html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
body {
  background: radial-gradient(
    circle at 10% 10%,
    #0d1117 0%,
    #10253e 60%,
    #0f172a 100%
  );
  height: 100vh;
  margin: 0;
}

#time {
  font-size: 14px;
}

.alert-head {
  font-size: 18px;
}

.transparent-table {
  background-color: transparent !important;
  font-family: "Russo One";
}

.transparent-table tr td {
  background-color: transparent !important;
  color: #fff;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-style: none;
  vertical-align: middle !important;
}

.transparent-table a {
  color: inherit; /* Bağlantının rengini çevresindeki metinle aynı yapar */
  text-decoration: none; /* Alt çizgiyi kaldırır */
}

.transparent-table thead tr th {
  background-color: transparent !important;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: none;
}

.transparent-table tr {
  margin: 50px;
}

.transparent-table tbody tr:hover {
  background-color: rgba(95, 113, 131, 0.2) !important; /* Gri tonu */
  border-radius: 10px;
}

hr {
  color: transparent;
}

.poollistdiv {
  max-width: 1000px;
  margin: 60px auto;
}

/* custom.css */
.nav-tabs {
  width: 197px;
  margin: 0 auto;
  border-radius: 0px !important;
  font-family: "Russo One";
  transition: none;
  border: 0px solid rgba(88, 179, 110, 1);
  text-decoration: none; /* Alt çizgiyi kaldırır */
  font-size: 33px;
}

.nav-link {
  color: white;
  font-family: "Russo One";
  border-radius: 0px;
  transition: none;
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active {
  color: #f4f0f0;
  border: -1px solid rgba(88, 179, 110, 0) !important; /* Border stilini kaldırmak için !important */
  border-radius: 0px !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus,
.nav-tabs .nav-link.active:active {
  /* Aktif link stilleri */
  background-color: #58b36e;
  color: white;
  border: 1px solid #58b36e !important;
  border-radius: 0px;
  font-family: "Russo One";
  transition: none;
}

.lendbtn {
  width: 100%;
  height: 43px;
  background-color: #212c42;
  color: white;
  border: 1px solid #212c42;
  border-radius: 5px;
  font-family: "Russo One";
  margin-top: 10px;
  /* Box shadow */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Yatay ofset, dikey ofset, bulanıklık yarıçapı ve renk */
}

.custom-alert {
  background-color: rgba(88, 179, 110, 0.1);
  border: 1px solid #fff; /* Çizgi şeklinde çerçeve */
  color: #fff;
  font-family: "Russo One";
}

.containerx {
  background: linear-gradient(to bottom, #141f32, #151f32);
  padding: 20px; /* İçerik ile çerçeve arasında boşluk */
  border-radius: 1rem;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  box-shadow: 0 0 0 1px #212c42;
}

.custom-input {
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
  background-color: transparent; /* Arka planı şeffaf yap */
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid #fff; /* Kenarlık ekle */
  border-radius: 4px; /* Yuvarlak köşeler */
  padding: 10px; /* İç boşluk */
  font-family: "Russo One";
}

.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Opacity ayarı (bazı tarayıcılarda gerekebilir) */
}

/* Odağı değiştirdiğinde input stilini özelleştir */
.custom-input:focus {
  background-color: #19232d;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.App {
  text-align: center;
  padding-bottom: 300px;
}

.App-logo {
  height: 5vmin;
  margin-top: 300px;
}

#nbb {
  box-shadow: 0 -10px 12px rgba(255, 255, 255, 0.5);
}

.fontify {
  font-family: "Russo One";
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Tüm çocuk bileşenleri ebeveynin genişliğine uyacak şekilde ayarlar */
  justify-content: flex-start; /* İçerikleri başlangıç noktasından itibaren sıralar */
  width: 100%; /* Genişliği ekran genişliğine uyumlu hale getirir */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#loqo {
  margin-top: 5px;
}

.semi-bold-text {
  font-family: "Noto Sans Display", sans-serif;
  font-weight: 600;
}

#mf {
  color: #fff; /* Beyaz metin rengi */
  height: 100px;
}

.ftxtc {
  color: #fff;
  text-align: center;
  font-family: Russo One;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ftxt {
  color: #fff;
  text-align: center;
  font-family: Russo One;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

.ftxtw {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Russo One;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ftxtw a {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-family: Russo One;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: wavy;
}

.wallet-adapter-button-trigger {
  color: white !important;
  font-family: Russo One !important;
  background-color: transparent !important;
}
.wallet-button {
  margin: 0 auto;
  border: 1px solid #212c42 !important;
  background-color: transparent !important;
  font-family: Russo One !important;
}

.wallet-button:hover {
  border: 1px solid #212c42 !important;
  color: white !important;
  font-family: Russo One !important;
}

.wbtn {
  width: 60%;
}
.footer {
  margin-top: auto;
}

#mhr {
  margin: 20px auto;
  color: gray;
  max-width: 600px;
  width: 100%;
}

#headmsg {
  background-image: radial-gradient(
    39.89% 976.52% at 29.48% 66.63%,
    #49afe9,
    #8fd7ff
  );
  cursor: pointer;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Russo One";
  margin: 0px auto;
  margin-top: 30px;
}

#headesp {
  font-family: "Russo One";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #98b0c1;
  margin-top: 10px;
}

.containery {
  display: flex; /* Flexbox kullanarak iç öğeleri yan yana sırala */
  justify-content: flex-end; /* İçerikleri sağa hizala */
  gap: 20px; /* Kutular arasında boşluk */
  /* Ekstra stil ayarlamaları */
}

.item {
  flex: 0 1 auto; /* Flex öğelerinin büyüyüp küçülebileceğini, ancak otomatik olarak kendi içeriğine göre boyutlandırılacağını belirtir */
  width: calc(
    33.33% - 20px
  ); /* Her kutu için genişlik ayarı, gap'i hesaba katarak */
  /* Veya sabit bir genişlik kullanabilirsiniz, örneğin: width: 200px; */
  /* Ekstra stil ayarlamaları */
}
.title {
  color: #98b0c1;
  font-family: "Russo One";
}
.value {
  /* Başlık ve değer için stil ayarlamaları */
  color: #fff;
  font-family: "Russo One";
  font-weight: bold;
}

/* FixedImage.css */
.fixed-image-link {
  position: fixed; /* Sabit pozisyon */
  bottom: 20px; /* Alt kenardan 20px uzaklık */
  right: 20px; /* Sağ kenardan 20px uzaklık */
  z-index: 1000; /* Diğer öğelerin üzerinde görünmesini sağlar */
}

.fixed-image {
  display: block; /* Blok seviyesinde görüntüleme */
  max-width: 100px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği otomatik ayarla */
  border: 0; /* Kenarlık yok */
}

/* FixedImage.css */
.fixed-left-image-link {
  position: fixed; /* Sabit pozisyon */
  bottom: 20px; /* Alt kenardan 20px uzaklık */
  left: 20px; /* Sağ kenardan 20px uzaklık */
  z-index: 1000; /* Diğer öğelerin üzerinde görünmesini sağlar */
}

.fixed-left-image-link-two {
  position: fixed; /* Sabit pozisyon */
  bottom: 20px; /* Alt kenardan 20px uzaklık */
  left: 50px; /* Sağ kenardan 20px uzaklık */
  z-index: 1000; /* Diğer öğelerin üzerinde görünmesini sağlar */
}

.fixed-left-image-link-three {
  position: fixed; /* Sabit pozisyon */
  bottom: 20px; /* Alt kenardan 20px uzaklık */
  left: 88px; /* Sağ kenardan 20px uzaklık */
  z-index: 1000; /* Diğer öğelerin üzerinde görünmesini sağlar */
}

.fixed-l-image {
  display: block; /* Blok seviyesinde görüntüleme */
  max-width: 20px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği otomatik ayarla */
  border: 0; /* Kenarlık yok */
}
.fixed-l-image-two {
  display: block; /* Blok seviyesinde görüntüleme */
  max-width: 27px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği otomatik ayarla */
  border: 0; /* Kenarlık yok */
}

.fixed-l-image-three {
  display: block; /* Blok seviyesinde görüntüleme */
  max-width: 16px; /* Maksimum genişlik */
  height: auto; /* Yüksekliği otomatik ayarla */
  border: 0; /* Kenarlık yok */
}
